import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import leftArrow from '../../assets/icons/left-arrow.png';
import leftArrowInactive from '../../assets/icons/left-arrow-inactive.png';
import rightArrow from '../../assets/icons/right-arrow.png';
import rightArrowInactive from '../../assets/icons/right-arrow-inactive.png';

export const BranchCardLocationPage = ({ branch }) => {
  const { t, i18n } = useTranslation('application');
  const currentLanguage = i18n.language;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => Math.min(branch.images.length - 1, prevIndex + 1));
  };

  const handleMapButtonClick = () => {
    window.open(branch.branchLink, '_blank');
  };

  return (
    <div className="branch-card-container">
      <div className="branch-card-image-container">
        <button type="button" onClick={handlePrevious}>
          <img
            className="arrow-icon left-arrow"
            src={currentImageIndex === 0 ? leftArrowInactive : leftArrow}
            alt="Previous"
          />
        </button>
        <img className="card-image" src={branch.images[currentImageIndex]} alt="Branch-cards" />
        <button type="button" onClick={handleNext}>
          <img
            className="arrow-icon right-arrow"
            src={currentImageIndex === branch.images.length - 1 ? rightArrowInactive : rightArrow}
            alt="Next"
          />
        </button>
      </div>
      <div className="card-text-box">
        <p className="branch-card-title">{branch.label[currentLanguage]}</p>
        <p className="branch-card-address">{branch.address[currentLanguage]}</p>
        <p className="branch-card-timings">{`${t('locationsPage.branchCard.everyday')}: ${t('locationsPage.branchCard.timing')}`}</p>
        <button className="branch-card-map" type="button" onClick={handleMapButtonClick}>{t('locationsPage.branchCard.map')}</button>
      </div>
    </div>
  );
};
