import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '../Text/Text';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';

import vat from '../../assets/images/vat.png';
import cert from '../../assets/images/vat-cert.jpg';
import saudi from '../../assets/images/saudi-made.png';
import sgs1 from '../../assets/images/ios-1.png';
import sgs2 from '../../assets/images/ios-2.png';
import sgs3 from '../../assets/images/ios-3.png';
import sgs4 from '../../assets/images/ios-4.png';
import madaCard from '../../assets/images/mada-logo.png';

import apple from '../../assets/images/apple-pay.png';
import master from '../../assets/images/mastercard-logo.png';
import stc from '../../assets/images/stcpay-logo.png';
import visa from '../../assets/images/visa-logo.png';

import instagram from '../../assets/icons/social/instagram.jpg';
import youtube from '../../assets/icons/social/youtube.jpg';
import twitter from '../../assets/icons/social/twitter.jpg';
import whatsapp from '../../assets/icons/social/whatsapp.jpg';
import snapchat from '../../assets/icons/social/snapchat.jpg';

import './footer.scss';

export const Footer = () => {
  const { t } = useTranslation('application');

  const socialMediaPlatforms = [
    { name: 'Instagram', link: t('footer.instagram'), imageSrc: instagram },
    { name: 'Youtube', link: t('footer.youtube'), imageSrc: youtube },
    { name: 'Twitter', link: t('footer.twitter'), imageSrc: twitter },
    { name: 'Snapchat', link: t('footer.snapchat'), imageSrc: snapchat },
    { name: 'Whatsapp', link: t('footer.whatsapp'), imageSrc: whatsapp },
  ];

  const footerImages = [
    { src: sgs1 },
    { src: saudi },
    { src: sgs2 },
    { src: sgs3 },
    { src: sgs4 },
    { src: madaCard, className: 'mada-card' },
    { src: stc, className: 'stc' },
    { src: apple },
    { src: master },
    { src: visa, className: 'visa' },
  ];

  const getFooterImages = () => footerImages.map((imageObject, index) => (
    <img
      key={index}
      src={imageObject.src}
      alt=""
      className={`each-image ${imageObject.className || ''}`}
    />
  ));

  const getSocialIcons = () => socialMediaPlatforms.map((platform, index) => (
    <div key={index}>
      <Link to={platform.link} target="_blank" rel="noreferrer">
        <img src={platform.imageSrc} alt={platform.name} />
      </Link>
    </div>
  ));

  return (
    <footer className="footer">
      <div className="footer-images-section">
        <div className="socials">{getSocialIcons()}</div>
        <div className="footer__images">
          <Link to={cert} target="_blank" rel="noreferrer">
            <span className="visually-hidden">{t('footer.cert')}</span>
            <img src={vat} alt="" className="each-image" />
          </Link>
          {getFooterImages()}
        </div>
      </div>
      <hr className="horizontal" />
      <div className="footer-copyright">
        <div className="commercial-presence">
          <div className="commercial">
            <Text tag="span" type="caption" className="caption">
              {t('footer.languageLabel')}
              {' '}
              <LanguageSwitcher />
            </Text>
          </div>
          <div className="cnv-container">
            <Text tag="span" type="caption" className="caption">
              {t('footer.crn')}
              :
              {' '}
              1010412094
            </Text>
            {' '}
            <Text tag="span" type="caption" className="caption">
              {t('footer.vrn')}
              :
              {' '}
              310166531800003
            </Text>
          </div>
        </div>
        <div className="rights-section">
          <div>
            <Text tag="span" type="caption" className="caption">
              {t('footer.rights')}
            </Text>
          </div>
          <div className="policies-container">
            <Link to="/policy">
              <Text tag="span" type="caption" className="caption policies">
                {t('footer.privacyPolicy')}
              </Text>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
