import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { ProductsList } from '../../components/ProductsList/ProductsList';
import { PackagesList } from '../../components/PackagesList/PackagesList';
import { Navbar } from '../../components/Navbar/Navbar';
import { Container } from '../../components/Layout/Container';
import { Footer } from '../../components/Footer/Footer';
import { ProductsFiltersSection } from './ProductsFiltersSection/ProductsFiltersSection';
import { BlogsSection } from '../../components/BlogsSection/BlogsSection';
import { BourbonSection } from '../../components/BourbonSection/BourbonSection';
import { HeroSection } from '../../components/HeroSection/HeroSection';
import { OurBranchesSection } from '../../components/OurBranchesSection/OurBranchesSection';

import './ProductsPage.scss';

export const ProductsPage = () => {
  const [showSectionOne, setShowSectionOne] = useState(false);
  const [showSectionTwo, setShowSectionTwo] = useState(false);
  const [showSectionThree, setShowSectionThree] = useState(false);
  const mainCategory = useSelector((state) => state.products.mainCategory);

  const listener = () => {
    const sectionOneTop = document
      .getElementById('our-branches-section')?.getBoundingClientRect()?.top;

    if (!sectionOneTop) return;

    if ((sectionOneTop / window.innerHeight) * 100 < 50) {
      setShowSectionOne(true);
    }

    const sectionTwoTop = document
      .getElementById('bourbon-section')?.getBoundingClientRect()?.top;

    if (!sectionTwoTop) return;

    if ((sectionTwoTop / window.innerHeight) * 100 < 50) {
      setShowSectionTwo(true);
    }

    const sectionThreeTop = document
      .getElementById('blog-section')?.getBoundingClientRect()?.top;

    if (!sectionThreeTop) return;

    if ((sectionThreeTop / window.innerHeight) * 100 < 50) {
      setShowSectionThree(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <div className="products-page-wrapper">
      <Navbar showSearchBar />
      <Fade>
        <HeroSection />
      </Fade>
      <Container className="products-section-wrapper">
        <ProductsFiltersSection />
        {mainCategory === 'Packages' ? <PackagesList /> : <ProductsList />}
      </Container>
      <Fade when={showSectionOne}>
        <OurBranchesSection />
      </Fade>
      <Fade when={showSectionTwo}>
        <BourbonSection />
      </Fade>
      <Fade when={showSectionThree}>
        <BlogsSection />
      </Fade>
      <Footer />
    </div>
  );
};
